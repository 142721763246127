import { useTranslations } from 'next-intl';
import { getErrorMessage } from '@iofinnet/io-core-cldsvc-errors-sdk';

import { ErrorCodeKeys } from '@/lib/errors';

export function useErrorTranslations(errorCode: string | undefined | null) {
  const errorT = useTranslations('Errors');

  let translatedErrorMessage: string;

  if (errorCode) {
    translatedErrorMessage = errorT(errorCode as ErrorCodeKeys);
    // Translations does not throw an error if the key is not found, so we need to check if the key is found
    if (translatedErrorMessage.startsWith('Errors.')) {
      const errorsSdkMessage = getErrorMessage(errorCode);
      if (errorsSdkMessage) {
        return errorsSdkMessage;
      } else {
        return errorT('UnknownErrorCode');
      }
    }
    return translatedErrorMessage;
  } else {
    return errorT('UnknownErrorCode');
  }
}
